import React, { Component } from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import { rhythm } from '../utils/typography'

class PostTags extends Component {
  render() {
    const { tags } = this.props
    return (
      <div css={{ marginBottom: rhythm(1) }}>
        <b css={{ marginRight: rhythm(1 / 4) }}>Tags:</b>
        {tags &&
          tags.map(tag => (
            <Link
              key={tag}
              to={`/tags/${_.kebabCase(tag)}`}
              css={{
                marginRight: `16px`,
              }}
            >
              {tag}
            </Link>
          ))}
      </div>
    )
  }
}

export default PostTags
