import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from "../components/layout"
import PostTags from '../components/PostTags'
import Img from 'gatsby-image'
import { rhythm } from '../utils/typography'

const gridStyle = {
  display: `grid`,
  gridGap: rhythm(1),
  '@media (min-width: 800px)': {
    gridTemplateColumns: `repeat(6, 1fr)`,
    '.post-body .third': {
      gridColumn: `span 2`,
    },
    '.post-body .half': {
      gridColumn: `span 3`,
    },
    '.post-body .two-third': {
      gridColumn: `span 4`,
    },
    '.post-body .full': {
      gridColumn: `span 6`,
    },
    '.post-body .full .prose': {
      maxWidth: `32em`,
      margin: `${rhythm(1)} auto`
    },
    '.post-body .intro': {
      marginBottom: rhythm(1),
    },
    '.post-body .center': {
      alignSelf: `center`,
    }
  },
}

const linkStyle = {
  margin: `.625rem`,
  borderBottom: `none`,
  boxShadow: `none`,
  fontWeight: `inherit`,
}

class ProjectTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { previous } = this.props.pageContext

    return (
      <Layout>
        <Helmet title={`${post.frontmatter.title} | ${siteTitle}`} />
        <Img
          fluid={post.frontmatter.introImage.childImageSharp.fluid}
          css={{ marginBottom: rhythm(2) }}
        />
        <h1 css={{ marginBottom: rhythm(1 / 4) }}>{post.frontmatter.title}</h1>
        <PostTags tags={post.frontmatter.tags} />

        <div
          css={{
            ...gridStyle,
            marginTop: rhythm(2),
            marginBottom: rhythm(2),
          }}
          className="post-body"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <hr/>
        {previous && (
          <div css={{
            textAlign: 'center',
            marginTop: rhythm(2)
          }}>
            <Link to={previous.fields.slug} rel="prev" className="linkStyle">
            Next up  → {' '} {previous.frontmatter.title}
            </Link>
          </div>
        )}
      </Layout>
    )
  }
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        introImage {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
